import React from 'react'
import styled from 'styled-components'

const Wrap = ({ children, bg }) => {
  // const [isVisible, setIsVisible] = useState(false)
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsVisible(true)
  //   }, 100)
  // })

  // return <El isVisible={isVisible}>{children}</El>
  return <El bg={bg}>{children}</El>
}

export default Wrap

const El = styled.div`
  padding-left: 60px;
  padding-right: 60px;
  position: relative;
  z-index: 2;

  

  color: ${props => props.theme.colors.white};
  background: ${props => (props.bg ? props.bg : props.theme.colors.greyDark)};

  /* transition: all 0.3s ease-out;
  opacity: ${props => (props.isVisible ? `1` : `0`)};
  transform: ${props =>
    props.isVisible ? `translateY(0px)` : `translateY(20px)`}; */

  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-left: 14px;
    padding-right: 14px;
  }
`
