// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-subtopic-js": () => import("/opt/build/repo/src/templates/subtopic.js" /* webpackChunkName: "component---src-templates-subtopic-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-billing-change-js": () => import("/opt/build/repo/src/pages/account-billing-change.js" /* webpackChunkName: "component---src-pages-account-billing-change-js" */),
  "component---src-pages-account-billing-history-js": () => import("/opt/build/repo/src/pages/account-billing-history.js" /* webpackChunkName: "component---src-pages-account-billing-history-js" */),
  "component---src-pages-account-billing-js": () => import("/opt/build/repo/src/pages/account-billing.js" /* webpackChunkName: "component---src-pages-account-billing-js" */),
  "component---src-pages-account-cancel-js": () => import("/opt/build/repo/src/pages/account-cancel.js" /* webpackChunkName: "component---src-pages-account-cancel-js" */),
  "component---src-pages-account-change-email-verify-js": () => import("/opt/build/repo/src/pages/account-change-email-verify.js" /* webpackChunkName: "component---src-pages-account-change-email-verify-js" */),
  "component---src-pages-account-change-email-js": () => import("/opt/build/repo/src/pages/account-change-email.js" /* webpackChunkName: "component---src-pages-account-change-email-js" */),
  "component---src-pages-account-change-pass-js": () => import("/opt/build/repo/src/pages/account-change-pass.js" /* webpackChunkName: "component---src-pages-account-change-pass-js" */),
  "component---src-pages-account-faq-js": () => import("/opt/build/repo/src/pages/account-faq.js" /* webpackChunkName: "component---src-pages-account-faq-js" */),
  "component---src-pages-account-goodbye-js": () => import("/opt/build/repo/src/pages/account-goodbye.js" /* webpackChunkName: "component---src-pages-account-goodbye-js" */),
  "component---src-pages-account-plan-change-js": () => import("/opt/build/repo/src/pages/account-plan-change.js" /* webpackChunkName: "component---src-pages-account-plan-change-js" */),
  "component---src-pages-account-setup-complete-js": () => import("/opt/build/repo/src/pages/account-setup-complete.js" /* webpackChunkName: "component---src-pages-account-setup-complete-js" */),
  "component---src-pages-account-setup-js": () => import("/opt/build/repo/src/pages/account-setup.js" /* webpackChunkName: "component---src-pages-account-setup-js" */),
  "component---src-pages-account-support-js": () => import("/opt/build/repo/src/pages/account-support.js" /* webpackChunkName: "component---src-pages-account-support-js" */),
  "component---src-pages-account-terms-js": () => import("/opt/build/repo/src/pages/account-terms.js" /* webpackChunkName: "component---src-pages-account-terms-js" */),
  "component---src-pages-account-js": () => import("/opt/build/repo/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-career-guide-js": () => import("/opt/build/repo/src/pages/career-guide.js" /* webpackChunkName: "component---src-pages-career-guide-js" */),
  "component---src-pages-career-modules-old-js": () => import("/opt/build/repo/src/pages/career-modules-old.js" /* webpackChunkName: "component---src-pages-career-modules-old-js" */),
  "component---src-pages-confirm-js": () => import("/opt/build/repo/src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-expired-js": () => import("/opt/build/repo/src/pages/expired.js" /* webpackChunkName: "component---src-pages-expired-js" */),
  "component---src-pages-expiring-soon-js": () => import("/opt/build/repo/src/pages/expiring-soon.js" /* webpackChunkName: "component---src-pages-expiring-soon-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-pass-js": () => import("/opt/build/repo/src/pages/forgot-pass.js" /* webpackChunkName: "component---src-pages-forgot-pass-js" */),
  "component---src-pages-graphic-design-js": () => import("/opt/build/repo/src/pages/graphic-design.js" /* webpackChunkName: "component---src-pages-graphic-design-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("/opt/build/repo/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sign-in-js": () => import("/opt/build/repo/src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("/opt/build/repo/src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-signed-out-js": () => import("/opt/build/repo/src/pages/signed-out.js" /* webpackChunkName: "component---src-pages-signed-out-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-teacher-guide-js": () => import("/opt/build/repo/src/pages/teacher-guide.js" /* webpackChunkName: "component---src-pages-teacher-guide-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tour-js": () => import("/opt/build/repo/src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-welcome-js": () => import("/opt/build/repo/src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

