import moment from 'moment'
import { navigate } from 'gatsby'

const weekLimit = 4
const navigateToExpiredRoutes = ['graphic-design', 'career-guide']

export const getDatePassed = authUser => {
  try {
    const { admin, teacher, graduate } = authUser.roles
    if (
      !admin &&
      !teacher &&
      authUser.stripe.subscriptionId === '' &&
      !graduate
    ) {
      const { intake } = authUser
      const endDate = moment.unix(intake.endDate.seconds).format('YYYY-MM-DD')
      const dateNow = moment().startOf('day')
      const weeksPassed = dateNow.diff(endDate, 'weeks', true)
      const isIntakeEnded = dateNow.diff(endDate) > 0
      const hasWeekPassed = weeksPassed > weekLimit

      return { isIntakeEnded, hasWeekPassed }
    } else {
      return false
    }
  } catch (err) {
    console.error(err)
    return false
  }
}

export default authUser => {
  try {
    const currentPath = window.location.pathname
    const baseUrl = window.location.pathname.split('/')[1]
    const currentHash = window.location.hash
    const expiredRoute = '/expired'
    const expiringSoonRoute = '/expiring-soon'
    const verifyHash = '#verify_user'
    const redirectRoute = '/graphic-design'
    const expiringSoonLS = 'showed-expiring-soon'
    const isExpiringSoonShown = localStorage.getItem(expiringSoonLS)

    const { isIntakeEnded, hasWeekPassed } = getDatePassed(authUser)

    const { graduate } = authUser.roles

    if (isIntakeEnded && !graduate) {
      if (hasWeekPassed) {
        if (
          navigateToExpiredRoutes.includes(baseUrl) ||
          currentPath === expiringSoonRoute
        )
          navigate(expiredRoute)
      } else {
        if (currentHash === verifyHash || !isExpiringSoonShown) {
          navigate(expiringSoonRoute)
          !isExpiringSoonShown && localStorage.setItem(expiringSoonLS, true)
        }
        currentPath === expiredRoute && navigate(expiringSoonRoute)
      }
    } else {
      if (
        currentHash === verifyHash ||
        currentPath === expiredRoute ||
        currentPath === expiringSoonRoute
      )
        navigate(redirectRoute)
    }
  } catch (err) {
    console.error(err)
  }
}
