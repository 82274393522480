import React, { Component, Fragment } from 'react'
import { ThemeProvider } from 'styled-components'
import { withWindowSizeListener } from 'react-window-size-listener'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import getFirebase, { FirebaseContext } from './firebase'
import withAuthentication from './session/withAuthentication'
import { AuthUserContext } from './session'
import _ from 'lodash'
// import Close from './page/close'

import 'react-image-lightbox/style.css'
import '../styles/video.css'
import '../styles/style.css'

// import PageBase from './page/base'
// import Nav from './nav'
import ShillingtonLogomark from './global/shillington-logomark'
import Tutorial from './tutorial'

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 800,
    speedAsDuration: true,
    offset: function() {
      return 135
    },
  })
}

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firebase: null,
    }
  }

  componentDidMount() {
    const app = import('firebase/app')
    const auth = import('firebase/auth')
    const firestore = import('firebase/firestore')
    const functions = import('firebase/functions')

    Promise.all([app, auth, firestore, functions]).then(values => {
      const firebase = getFirebase(values[0])
      this.setState({ firebase })
    })
  }

  render() {
    const { windowSize, children, location } = this.props
    const pathname = location.pathname.replace(/\/$/, '')

    if (windowSize) {
      if (windowSize.windowWidth) {
        return (
          <FirebaseContext.Provider value={this.state.firebase}>
            <AppWithAuthentication pathname={pathname}>
              {children}
            </AppWithAuthentication>
          </FirebaseContext.Provider>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }
}

const AppWithAuthentication = withAuthentication(props => {
  const themeMod = user => {
    const newTheme = _.cloneDeep(theme)
    if (user && user.prefs && user.prefs.themeMode) {
      newTheme.materialTheme =
        user.prefs.themeMode === 'dark'
          ? newTheme.materialThemeOptions.dark
          : newTheme.materialThemeOptions.light
    }
    return newTheme
  }
  return (
    <AuthUserContext.Consumer>
      {({ authUser }) => <AppBody {...props} theme={themeMod(authUser)} />}
    </AuthUserContext.Consumer>
  )
})

const AppBody = props => {
  const { children, pathname, theme } = props

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <>
          {/* <PageBase /> */}
          <ShillingtonLogomark pathname={pathname} />
          {/* <Nav pathname={pathname} /> */}
          {children}
          {/* <Close pathname={pathname} /> */}
          <Tutorial />
        </>
      </ThemeProvider>
      <GlobalStyle theme={theme} />
    </Fragment>
  )
}

export default withWindowSizeListener(Layout)
