import React from 'react'
import styled from 'styled-components'

const Box = ({ children, width, justify, margin }) => {
  const boxJustify = justify ? justify : `space-between`

  const inner = width === 'col'

  let boxMarginBottom = `1em`
  if (margin === 'small') {
    boxMarginBottom = `1em`
  } else if (margin === 'medium') {
    boxMarginBottom = `2.5em`
  }

  let boxWidth = `100%`
  if (width === 'col') {
    boxWidth = `calc(50% - 11px)`
  } else if (width === 'full') {
    boxWidth = `calc(100%)`
  }

  return (
    <El
      width={boxWidth}
      justify={boxJustify}
      marginBottom={boxMarginBottom}
      inner={inner}
    >
      {children}
    </El>
  )
}

export default Box

const El = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${props => props.width};
  justify-content: ${props => props.justify};
  margin-bottom: ${props => props.marginBottom};
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    width: 100%;
    margin-bottom: ${props => (props.inner ? `0em` : `1em`)};
  }
`
