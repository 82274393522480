/*eslint no-undef: "off"*/

module.exports = {
  siteTitle: 'The Guide - Shillington Education',
  siteDescription: 'The graphic design handbook, reinvented',
  siteUrl: 'https://theguide.design', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'Shilling Education', // Author for RSS author segment and SEO schema
  authorUrl: 'https://theguide.design/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@shillington_', // Change for Twitter Cards
  shortTitle: 'The Guide', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/shillington-logo.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © 2020 Shillington Education', // Copyright string for the RSS feed
  totalCourseReleases: 4,
  campuses: [
    'Online',
    'Sydney',
    'Melbourne',
    'Brisbane',
    'New York',
    'London',
    'Manchester',
  ],
  campusesShort: ['SYD', 'MEL', 'BNE', 'NY', 'LDN'],
  courseTypes: ['Part Time', 'Full Time', 'Digital/Masterclass'],
  courseTypesShort: ['PT', 'FT'],
  userPrefs: {
    fontSize: 'md', // string: md, lg
    fullScreen: false, // bool
    themeMode: 'light', // string: light, dark
  },
  currencyOptions: [
    {
      country: 'US',
      currency: 'USD',
      symbol: '$',
      plans: [
        {
          name: 'Monthly',
          id: process.env.GATSBY_STRIPE_PLAN_MONTHLY_US,
          value: '1000',
        },
        {
          name: 'Yearly (2 months free)',
          id: process.env.GATSBY_STRIPE_PLAN_YEARLY_US,
          value: '10000',
        },
      ],
    },
    {
      country: 'AU',
      currency: 'AUD',
      symbol: '$',
      plans: [
        {
          name: 'Monthly',
          id: process.env.GATSBY_STRIPE_PLAN_MONTHLY_AU,
          value: '1200',
        },
        {
          name: 'Yearly (2 months free)',
          id: process.env.GATSBY_STRIPE_PLAN_YEARLY_AU,
          value: '12000',
        },
      ],
    },
    {
      country: 'UK',
      currency: 'GBP',
      symbol: '£',
      plans: [
        {
          name: 'Monthly',
          id: process.env.GATSBY_STRIPE_PLAN_MONTHLY_UK,
          value: '1000',
        },
        {
          name: 'Yearly (2 months free)',
          id: process.env.GATSBY_STRIPE_PLAN_YEARLY_UK,
          value: '10000',
        },
      ],
    },
  ],
}
