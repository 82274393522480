import React from 'react'
import styled from 'styled-components'

const ButtonLink = props => {
  const { children, ...rest } = props

  return <El {...rest}>{children}</El>
}

export default ButtonLink

const El = styled.div`
  display: inline-block;
  a {
    ${props => props.theme.fontSizing(props.theme.font.buttonSizes)};
    border: 2px solid ${props => props.theme.colors.highlight1};
    color: ${props => props.theme.colors.white};
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    display: inline-block;
    margin: 0;
    line-height: 100%;
    border-radius: 48px;
    padding: 0px 18px 3px 18px;
    height: 48px;
    display: flex;
    align-items: center;
    @media screen and (max-width: ${props => props.theme.responsive.md}) {
      height: 40px;
      padding: 0px 13px 2px 13px;
    }
    @media screen and (max-width: ${props => props.theme.responsive.sm}) {
      height: 37px;
    }
  }
`
