import React from 'react'
import styled from 'styled-components'

const Button = props => {
  const { onClick, children, isLarge, style, ...rest } = props

  const btnProps = {
    onClick,
    isLarge,
    ...rest,
  }

  switch (style) {
    case 'no-style':
      return <NoStyle {...btnProps}>{children}</NoStyle>

    case 'alt':
      return <Alt {...btnProps}>{children}</Alt>

    case 'back':
      return <Back {...btnProps}>{children}</Back>

    default:
      return <Default {...btnProps}>{children}</Default>
  }
}

export default Button

const BaseButton = styled.button`
  border: 2px solid ${props => props.theme.colors.highlight1};
  background: ${props => props.theme.colors.greyDark};
  ${props =>
    props.theme.fontSizing(
      props.isLarge
        ? props.theme.font.buttonLrgSizes
        : props.theme.font.buttonSizes
    )};
  font-weight: bold;
  cursor: pointer;
  outline: none;
  display: inline-block;
  margin: 0;
  line-height: 100%;
  border-radius: 40px;
  color: ${props => props.theme.colors.white};

  ${props =>
    props.isLarge
      ? `padding: 0px 32px 3px 32px; height: 57px;`
      : `padding: 0px 18px 2px 18px; height: 48px;`};

  display: flex;
  align-items: center;

  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    ${props =>
      props.isLarge
        ? `padding: 0px 23px 2px 23px; height: 52px;`
        : `padding: 0px 18px 1px 18px; height: 48px;`};
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    ${props =>
      props.isLarge
        ? `padding: 0px 15px 1px 15px; height: 40px;`
        : `padding: 0px 13px 1px 13px; height: 37px;`};
  }
`

const Default = styled(BaseButton)``

const NoStyle = styled(BaseButton)`
  background: transparent;
  color: ${props => props.theme.colors.white};
  padding: 0;
  font-weight: normal;
  border: none;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding: 0;
  }
`

const Back = styled(BaseButton)`
  background: ${props => props.theme.colors.greyDark};
  color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.greyDark2};
  font-weight: normal;
`

const Alt = styled(BaseButton)`
  background: ${props => props.theme.colors.highlight1};
`
