import React from 'react'
import styled from 'styled-components'

export default ({ children }) => {
  return <Title>{children}</Title>
}

const Title = styled.div`
  ${props => props.theme.fontSizing(props.theme.font.formTitleSizes)};
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  margin-bottom: 1em;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    margin-bottom: 0.5em;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    margin-bottom: 0.5em;
  }
`
