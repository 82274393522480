import React from 'react'
import styled from 'styled-components'

const FormFields = ({ children, isProcessing }) => {
  return <El fade={isProcessing}>{children}</El>
}

export default FormFields

const El = styled.div`
  display: flex;
  flex-wrap: wrap;
  transition: opacity 0.3s ease-out;
  opacity: ${props => (props.fade ? `0.4` : `1`)};
`
