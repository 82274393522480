import React from 'react'
import { Link } from 'gatsby'
import SvgLogomark from '../svg/logomark'
import styled from 'styled-components'

export default () => {
  return (
    <Logo>
      <Link to="/">
        <SvgLogomark />
      </Link>
    </Logo>
  )
}

const Logo = styled.div`
  position: relative;
  top: -71px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    svg {
      width: 84px;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    top: 0px;
    display: none;
  }
`
