import { userPrefs } from '../../utils/siteConfig'
import * as Sentry from '@sentry/browser'

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
}

class Firebase {
  constructor(app) {
    app.initializeApp(config)

    this.firestore = app.firestore

    /* Firebase APIs */

    this.functions = app.functions()

    if (process.env.NODE_ENV === 'development') {
      this.functions.useFunctionsEmulator('http://localhost:5000')
    }

    this.auth = app.auth()
    this.db = app.firestore()
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .then(snapshot => {
            if (snapshot.exists) {
              const dbUser = snapshot.data()
              const dbUserId = snapshot.id
              if (!dbUser.hasOwnProperty('prefs')) {
                dbUser.prefs = userPrefs
              }
              // mutate authUser
              authUser = {
                authUid: authUser.uid,
                uid: dbUserId,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                intake: {},
                ...dbUser,
              }
              // mutate authUser's intake
              this.intakesConfirmed(
                dbUserId,
                this.firestore.Timestamp.fromDate(new Date())
              )
                .get()
                .then(intakeSnapshot => {
                  if (intakeSnapshot.docs.length > 0) {
                    const dbIntake = intakeSnapshot.docs[0].data()
                    authUser.intake = dbIntake
                    next(authUser)
                  } else {
                    Sentry.withScope(scope => {
                      scope.setUser({ id: authUser.uid, email: authUser.email })
                      Sentry.captureException('Intake not found')
                    })
                    next(authUser)
                  }
                })
                .catch(error => {
                  Sentry.captureException(error)
                  next(authUser)
                })
            } else {
              Sentry.withScope(scope => {
                scope.setUser({ id: authUser.uid, email: authUser.email })
                Sentry.captureException('User not found')
              })
              fallback()
            }
          })
          .catch(error => {
            Sentry.captureException(error)
            fallback()
          })
      } else {
        fallback()
      }
    })

  // *** User API ***

  user = uid =>
    this.db
      .collection('users')
      .doc(uid)
      .get()

  userCreate = (uid, data) =>
    this.db
      .collection('users')
      .doc(uid)
      .set(data)

  userUpdate = (uid, data) =>
    this.db
      .collection('users')
      .doc(uid)
      .update(data)

  userByEmail = email =>
    this.db
      .collection('users')
      .where('email', '==', email)
      .limit(1)

  // *** Intakes API ***

  intake = uid =>
    this.db
      .collection('intakes')
      .doc(uid)
      .get()

  intakeUpdate = (uid, data) =>
    this.db
      .collection('intakes')
      .doc(uid)
      .update(data)

  intakesInvited = (studentId, beforeDate) =>
    this.db
      .collection('intakes')
      .where(`students.${studentId}.invitedAt`, '<', beforeDate)

  intakesConfirmed = (studentId, beforeDate) =>
    this.db
      .collection('intakes')
      .where(`students.${studentId}.confirmedAt`, '<', beforeDate)

  intakesInviteAccepted = (studentId, beforeDate) =>
    this.db
      .collection('intakes')
      .where(`students.${studentId}.inviteAcceptedAt`, '<', beforeDate)

  shilumniIntakesGet = (campus, startDateTs, courseType) =>
    this.db
      .collection('intakes')
      .where(`campus`, '==', campus)
      .where(`courseType`, '==', courseType)
      .where(`startDate`, '>', startDateTs)
      .get()
}

let firebase

function getFirebase(app, auth, firestore) {
  if (!firebase) {
    firebase = new Firebase(app, auth, firestore)
  }
  return firebase
}

export default getFirebase
