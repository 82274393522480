import React from 'react'
import styled from 'styled-components'

// import TutorialImg from '../../images/tutorial/tutorial-slide-1.png'

export default ({ body, label, description, isActive }) => {
  return (
    <Slide isActive={isActive}>
      <Img>
        <ImgInner>{body}</ImgInner>
      </Img>
      <Caption>
        <Heading>{label}</Heading>
        {description}
      </Caption>
    </Slide>
  )
}

const Slide = styled.div`
  opacity: ${props => (props.isActive ? `1.0` : `0.7`)};
  width: ${props => (props.isActive ? `100%` : `0px`)};
  height: ${props => (props.isActive ? `auto` : `0px`)};
  overflow: ${props => (props.isActive ? `visible` : `hidden`)};
`

const Img = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */

  height: 0;
  overflow: hidden;
  padding-top: ${(400 / 888) * 100}%;
  position: relative;
`

const ImgInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Caption = styled.div`
  ${props => props.theme.fontSizing(props.theme.font.baseSizes)};
  h3 {
    text-align: center;
    font-size: 110%;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  p {
    text-align: center;
    padding: 0 20%;
    min-height: 70px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    p {
      padding: 0 10%;
      min-height: 50px;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
  }
`

const Heading = styled.h3`
  font-weight: bold;
`
