import React from 'react'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'

export default ({ children }) => {
  return (
    <SectionBody>
      <Flex flexWrap="nowrap">
        <Box width={[1, 3 / 5]} ml={[1, `${(1 / 5) * 100}%`]}>
          {children}
        </Box>
      </Flex>
    </SectionBody>
  )
}

const SectionBody = styled.div`
  padding-top: 0px;
  margin-bottom: 60px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-top: 0px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    margin-bottom: 2em;
  }
`
