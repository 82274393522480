import React from 'react'
import styled from 'styled-components'
import IconBack from '../svg/icon-back'
import { Link } from 'gatsby'

export default ({ url }) => {
  const validUrl = url ? url : '/'
  return (
    <Wrap>
      <Link to={validUrl}>
        <IconBack />
      </Link>
    </Wrap>
  )
}

const Wrap = styled.div`
  a {
    ${props => props.theme.circleButton(props.theme.colors.highlight1)};
    @media screen and (max-width: ${props => props.theme.responsive.md}) {
      svg {
        width: 16px;
      }
    }
  }
`
