import React from 'react'
import styled from 'styled-components'

const FormError = ({ children }) => {
  return <El>{children}</El>
}

export default FormError

const El = styled.div`
  color: ${props => props.theme.colors.error};
  ${props => props.theme.fontSizing(props.theme.font.inputErrorSizes)};
  margin-top: 0.2em;
`
