import React from 'react'
import theme from '../../styles/theme'

export default () => {
  const fill = theme.colors.white
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.474609 8.31152H14.9443L8.02637 1.39355L9.25098 0.179688L18.2422 9.1709L9.25098 18.1621L8.02637 16.9482L14.9443 10.0303H0.474609V8.31152Z"
        fill={fill}
      />
    </svg>
  )
}
