import React from 'react'
import styled from 'styled-components'

export default ({ children }) => {
  return <Errors>{children}</Errors>
}

const Errors = styled.div`
  color: ${props => props.theme.colors.error};
  border: 3px solid ${props => props.theme.colors.error};
  padding: 7px 18px;
  border-radius: 30px;
  margin-bottom: 1em;
  p {
    color: ${props => props.theme.colors.error};
    margin-top: 0px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`
