import React from 'react'
import styled from 'styled-components'

const FormField = ({ children, width, marginRight, marginBottom }) => {
  const fieldProps = {
    width,
    marginRight,
    marginBottom,
  }

  return <El {...fieldProps}>{children}</El>
}

export default FormField

const El = styled.div`
  width: ${props => (props.width ? props.width : `calc(100% - 4px)`)};
  padding: 0;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom : `1.5em`};
  margin-right: ${props => (props.marginRight ? props.marginRight : `0%`)};
  ${props => props.theme.fontSizing(props.theme.font.baseSizes)};
  p {
    color: ${props => props.theme.colors.greyDark5};
  }

  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    width: 100%;
    margin-right: 0px;
    padding: 0;
    margin-bottom: 1em;
    /* &:last-child {
      margin-bottom: 0em;
    } */
  }
`
