import React from 'react'
import { navigate } from 'gatsby'
import AuthUserContext from './context'
import { withFirebase } from '../firebase'
import validateUser from '../../utils/validateUser'

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    _initFirebase = false

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true
        this.listener = this.props.firebase.onAuthUserListener(
          authUser => {
            validateUser(authUser)
            if (!condition(authUser)) {
              navigate('/sign-in')
            }
          },
          () => {
            navigate('/')
          }
        )
      }

      // const expired = true
      // expired && navigate('/expired')
    }

    componentDidMount() {
      this.firebaseInit()
    }

    componentDidUpdate() {
      this.firebaseInit()
    }

    componentWillUnmount() {
      this.listener && this.listener()
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {({ authUser, updateAuthUserPrefs }) =>
            condition(authUser) ? (
              <Component
                {...this.props}
                authUser={authUser}
                updateAuthUserPrefs={updateAuthUserPrefs}
              />
            ) : null
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return withFirebase(WithAuthorization)
}

export default withAuthorization
