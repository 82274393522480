import React from 'react'
import styled from 'styled-components'

export default ({ children }) => {
  return <Wrap>{children}</Wrap>
}

const Wrap = styled.div`
  display: flex;
  a,
  button {
    margin-right: 1em;
  }
`
