import React from 'react'
import styled from 'styled-components'
import Logo from '../svg/shillington-logomark'

export default ({ pathname }) => {
  // console.log('>>>', pathname)

  let isVisible = true

  const graphicDesignSubPageRegEx = /\/graphic-design\/?(.*)/g
  const graphicDesignMatches = graphicDesignSubPageRegEx.exec(pathname)

  const careerGuideSubPageRegEx = /\/career-guide\/?(.*)/g
  const careerGuideMatches = careerGuideSubPageRegEx.exec(pathname)

  if (
    (graphicDesignMatches && graphicDesignMatches[1]) ||
    (careerGuideMatches && careerGuideMatches[1])
  ) {
    isVisible = false
  }

  return (
    <Mark isVisible={isVisible}>
      <a
        href="https://shillingtoneducation.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Logo />
      </a>
    </Mark>
  )
}

const Mark = styled.div`
  position: fixed;
  right: 46px;
  z-index: 500;
  transition: bottom 0.6s ease-out;
  bottom: ${props => (props.isVisible ? `-30px` : `-200px`)};
  svg {
    width: 150px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    right: 22px;
    bottom: ${props => (props.isVisible ? `-27px` : `-150px`)};
    svg {
      width: 124px;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    right: 10px;
    bottom: ${props => (props.isVisible ? `-25px` : `-120px`)};
    svg {
      width: 106px;
    }
  }
`
