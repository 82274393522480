import React from 'react'
import styled from 'styled-components'

const Copy = ({ children }) => {
  return <El>{children}</El>
}

export default Copy

const El = styled.div`
  ${props => props.theme.fontSizing(props.theme.font.baseSizes)};
  ${props => props.theme.pageCopy()};
  width: 100%;
  max-width: 840px;
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0em;
    }
  }
`
