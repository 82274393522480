import React from 'react'
import styled from 'styled-components'

const FormCopy = ({ children }) => {
  return <El>{children}</El>
}

export default FormCopy

const El = styled.div`
  ${props => props.theme.fontSizing(props.theme.font.baseSizes)};
  color: ${props => props.theme.colors.greyDark5};
  p {
    color: ${props => props.theme.colors.greyDark5};
    margin-top: 0px;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    border-bottom: 2px solid ${props => props.theme.colors.highlight1};
  }
`
