import React from 'react'
import _ from 'lodash'

import AuthUserContext from './context'
import { withFirebase } from '../firebase'

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    _initFirebase = false

    constructor(props) {
      super(props)

      this.state = {
        authUser: null,
        updateAuthUserPrefs: this.updateAuthUserPrefs,
      }
    }

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true
        this.listener = this.props.firebase.onAuthUserListener(
          authUser => {
            localStorage.setItem('authUser', JSON.stringify(authUser))
            this.setState({ authUser })
          },
          () => {
            localStorage.removeItem('authUser')
            this.setState({ authUser: null })
          }
        )
      }
    }

    updateAuthUserPrefs = newPrefs => {
      const { authUser } = this.state
      const { firebase } = this.props

      // Optimistic (client side) update for speed
      const updatedUser = _.cloneDeep(authUser)
      updatedUser.prefs = newPrefs
      localStorage.setItem('authUser', JSON.stringify(updatedUser))
      this.setState({
        authUser: updatedUser,
      })

      // Server update
      const userUpdateData = {
        prefs: newPrefs,
      }
      firebase
        .userUpdate(authUser.uid, userUpdateData)
        .then(() => {
          console.log('Prefs updated ✔')
        })
        .catch(error => {
          console.log('Pref update error ✘', error)
        })
    }

    componentDidMount() {
      this.setState({
        authUser: JSON.parse(localStorage.getItem('authUser')),
      })
      this.firebaseInit()
    }

    componentDidUpdate() {
      this.firebaseInit()
    }

    componentWillUnmount() {
      this.listener && this.listener()
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
