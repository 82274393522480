import React, { useState } from 'react'
import styled from 'styled-components'
import { AuthUserContext } from '../session'
import { withFirebase } from '../firebase'
import _ from 'lodash'
import { Button } from '../page/components'
import IconClose from '../svg/icon-close'
import IconNext from '../svg/icon-next'
import IconPrev from '../svg/icon-prev'

import TutorialImg1 from '../../images/tutorial/tutorial-slide-1.png'
import TutorialImg2 from '../../images/tutorial/tutorial-slide-2.png'
import TutorialImg3 from '../../images/tutorial/tutorial-slide-3.png'
import TutorialImg4 from '../../images/tutorial/tutorial-slide-4.png'
import TutorialImg5 from '../../images/tutorial/tutorial-slide-5.png'
import DoneImg from '../../images/tutorial/wayne-thumbs-up.gif'

import Slide from './slide'

const Tutorial = ({ firebase }) => {
  return (
    <AuthUserContext.Consumer>
      {({ authUser }) =>
        authUser ? <Modal authUser={authUser} firebase={firebase} /> : null
      }
    </AuthUserContext.Consumer>
  )
}

const Modal = ({ authUser, firebase }) => {
  const [clientClosed, setClientClosed] = useState(null)
  const [currSlide, setCurrSlide] = useState(0)

  const handleClick = async () => {
    setClientClosed(true)
    if (firebase) {
      await firebase.userUpdate(authUser.uid, {
        isTutorial: false,
      })

      // update local state (for quick refresh)
      const updatedUser = _.cloneDeep(authUser)
      updatedUser.isTutorial = false
      localStorage.setItem('authUser', JSON.stringify(updatedUser))
    }
  }

  if (authUser && authUser.isTutorial && clientClosed === null) {
    const slides = [
      {
        body: <img src={TutorialImg1} />,
        label: 'Expand Topics',
        description: (
          <p>
            Select a topic to expand subtopics and jump into the relevant lesson
            material for the day.
          </p>
        ),
      },
      {
        body: (
          <Title>
            Our mini guide
            <br />
            to using The Guide
          </Title>
        ),
        label: 'How It Works',
        description: (
          <p>
            The Guide is easy to use, here’s an overview of the key features and
            how they work.
          </p>
        ),
      },
      {
        body: <img src={TutorialImg1} />,
        label: 'Expand Topics',
        description: (
          <p>
            Select a topic to expand subtopics and jump into the relevant lesson
            material for the day.
          </p>
        ),
      },
      {
        body: <img src={TutorialImg2} />,
        label: 'Search Material',
        description: (
          <p>
            Select Search in the top of the page, then type in lesson codes,
            keywords or topics, to see search results.
          </p>
        ),
      },
      {
        body: <img src={TutorialImg3} />,
        label: 'Access Material',
        description: (
          <p>
            Once logged in, Graphic Design material is exclusive to current
            students. Shillington graduates can access the Career Guide.
          </p>
        ),
      },
      {
        body: <img src={TutorialImg4} />,
        label: 'Jump-to menu/chapter',
        description: <p>Jump to specific chapters within a subtopic.</p>,
      },
      {
        body: <img src={TutorialImg5} />,
        label: 'Text size, focus and dark mode',
        description: (
          <p>
            Use the tools in the top right to change text size for increased
            legibility, and switch between focus, light and dark modes.
          </p>
        ),
      },
      {
        body: <img src={DoneImg} />,
        label: 'You’re Ready!',
        description: (
          <End>
            <Button style="alt" onClick={handleClick}>
              Get Started
            </Button>
          </End>
        ),
      },
    ]

    const slideContent = slides.map((slide, index) => {
      const key = `slide-${index}`
      return (
        <Slide
          key={key}
          body={slide.body}
          label={slide.label}
          description={slide.description}
          isActive={currSlide === index}
        />
      )
    })

    const navContent = slides.map((slide, index) => {
      const key = `nav-${index}`
      return (
        <NavItem
          key={key}
          isActive={currSlide === index}
          onClick={() => setCurrSlide(index)}
        />
      )
    })

    const nextContent =
      currSlide < slides.length - 1 ? (
        <Next>
          <button onClick={() => setCurrSlide(currSlide + 1)}>
            <IconNext />
          </button>
        </Next>
      ) : null

    const prevContent =
      currSlide !== 0 ? (
        <Prev>
          <button onClick={() => setCurrSlide(currSlide - 1)}>
            <IconPrev />
          </button>
        </Prev>
      ) : null

    return (
      <Wrap>
        <Box>
          {slideContent}
          <Nav>{navContent}</Nav>
          {nextContent}
          {prevContent}
        </Box>
        <Close>
          <button onClick={handleClick}>
            <IconClose isDark={true} />
          </button>
        </Close>
      </Wrap>
    )
  } else {
    return null
  }
}

export default withFirebase(Tutorial)

const Wrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

const Box = styled.div`
  background: #fff;
  padding: 40px;
  width: 888px;
  padding-bottom: 70px;
  position: relative;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    width: 740px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    width: 90%;
  }
`

const Nav = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  text-align: center;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    bottom: 30px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    bottom: 30px;
  }
`

const NavItem = styled.a`
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 10px;
  background: ${props =>
    props.isActive
      ? props.theme.colors.highlight1
      : props.theme.colors.greyLight1};
  margin: 0 5px;
`

const Title = styled.div`
  ${props => props.theme.fontSizing(props.theme.font.formTitleSizes)};
  font-weight: bold;
`

const End = styled.p`
  display: flex;
  justify-content: center;
`

const Close = styled.div`
  position: absolute;
  right: 60px;
  top: 26px;
  z-index: 2;
  button {
    ${props =>
      props.theme.circleButton(
        props.isDark
          ? props.theme.colors.greyDark2
          : props.theme.colors.highlight1
      )};
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    top: 19px;
    right: 35px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    top: 19px;
    right: 14px;
  }
`

const Next = styled.div`
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  button {
    ${props =>
      props.theme.circleButton(
        props.isDark
          ? props.theme.colors.greyDark2
          : props.theme.colors.highlight1
      )};
    svg {
      position: relative;
      top: 1px;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    right: -50px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    top: 105%;
    transform: translateY(0%);
    right: 0px;
  }
`

const Prev = styled.div`
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  button {
    ${props =>
      props.theme.circleButton(
        props.isDark
          ? props.theme.colors.greyDark2
          : props.theme.colors.highlight1
      )};
    svg {
      position: relative;
      top: 1px;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    left: -50px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    top: 105%;
    transform: translateY(0%);
    left: 0px;
  }
`
