import React from 'react'
import styled from 'styled-components'

export default ({ children }) => {
  return <HideMob>{children}</HideMob>
}

const HideMob = styled.div`
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    display: none;
  }
`
