import React from 'react'

const Logomark = () => {
  //let width = 190
  /*
  if (windowSize.windowWidth < 900) {
    width = 110;
  } else if (windowSize.windowWidth < 1600) {
    width = 130;
  } else {
    width = 190;
  }
  */

  return (
    <svg
      width="104"
      height="71"
      viewBox="0 0 104 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.3121 15.3827C66.8261 15.7877 66.2591 16.0577 65.5571 16.0577C64.9361 16.0577 64.3691 15.8417 64.3691 14.9777V9.06468H68.2841V5.39268H64.3691V2.01768H59.4281V5.39268H57.2141V9.06468H59.4281V14.9507C59.4281 16.4627 59.6711 17.5427 60.3461 18.4877C61.2371 19.7297 62.7221 20.3237 64.6661 20.3237C66.5831 20.3237 68.0141 19.7297 69.0941 18.8927L67.3121 15.3827Z"
        fill="white"
      />
      <path
        d="M85.3175 19.9997V10.1987C85.3175 7.49868 83.8595 5.09568 80.4845 5.09568C78.9995 5.09568 77.4875 5.68968 76.3805 6.74268V0.72168H71.4395V19.9997H76.3805V11.6027C76.3805 10.0907 77.3795 9.41568 78.5135 9.41568C80.0255 9.41568 80.3765 10.3337 80.3765 11.3327V19.9997H85.3175Z"
        fill="white"
      />
      <path
        d="M103.569 12.6287C103.569 8.09268 100.788 5.09568 95.9823 5.09568C90.9873 5.09568 88.0983 8.36268 88.0983 12.7907C88.0983 17.0837 90.8253 20.3237 96.2523 20.3237C99.6273 20.3237 101.922 19.0817 103.569 17.4617L100.356 14.6537C99.4924 15.5987 98.1963 16.2737 96.5493 16.2737C94.7673 16.2737 93.5253 15.4907 93.4173 13.9247H103.515C103.542 13.5737 103.569 13.0877 103.569 12.6287ZM98.7363 10.9547H93.4173C93.5523 9.38868 94.6053 8.63268 96.0903 8.63268C97.5753 8.63268 98.6283 9.41568 98.7363 10.9547Z"
        fill="white"
      />
      <path
        d="M41.214 28.3927H36.273V29.7697C35.166 28.6897 33.789 28.0957 32.115 28.0957C28.038 28.0957 25.5 31.2277 25.5 35.0617C25.5 38.9227 28.038 42.0277 32.115 42.0277C33.789 42.0277 35.166 41.4337 36.273 40.3807V41.1367C36.273 43.2157 34.491 43.9177 32.304 43.9177C30.468 43.9177 28.902 43.5397 27.606 42.7837L25.986 46.5097C27.822 47.5087 29.901 47.9677 32.439 47.9677C35.22 47.9677 37.92 47.3467 39.513 45.5917C40.89 44.0797 41.214 42.2437 41.214 39.8677V28.3927ZM36.273 35.0347C36.273 36.7357 35.22 38.0587 33.438 38.0587C31.656 38.0587 30.711 36.7627 30.711 35.0347C30.711 33.2797 31.656 32.0647 33.438 32.0647C35.274 32.0647 36.273 33.3337 36.273 35.0347Z"
        fill="white"
      />
      <path
        d="M57.8893 42.9997V28.3927H52.9753V36.8167C52.9753 38.3287 51.9492 38.9767 50.8152 38.9767C49.3032 38.9767 48.9522 38.0587 48.9522 37.0597V28.3927H44.0383V38.1937C44.0383 40.9207 45.4962 43.3237 48.8712 43.3237C50.3562 43.3237 51.8683 42.7027 52.9753 41.6767V42.9997H57.8893Z"
        fill="white"
      />
      <path
        d="M66.5574 26.8807V23.7217H61.6164V26.8807H66.5574ZM66.5574 42.9997V28.3927H61.6164V42.9997H66.5574Z"
        fill="white"
      />
      <path
        d="M85.2103 42.9997V23.7217H80.2693V29.7427C79.1623 28.6897 77.7853 28.0957 76.1113 28.0957C72.0343 28.0957 69.4963 31.3357 69.4963 35.7097C69.4963 40.0027 72.0343 43.3237 76.1113 43.3237C77.7853 43.3237 79.1623 42.7027 80.2693 41.6497V42.9997H85.2103ZM80.2693 35.7367C80.2693 37.5997 79.3513 39.1117 77.4343 39.1117C75.5713 39.1117 74.7073 37.5727 74.7073 35.7367C74.7073 33.9007 75.5713 32.2807 77.4343 32.2807C79.3243 32.2807 80.2693 33.8737 80.2693 35.7367Z"
        fill="white"
      />
      <path
        d="M103.569 35.6287C103.569 31.0927 100.788 28.0957 95.9823 28.0957C90.9873 28.0957 88.0983 31.3627 88.0983 35.7907C88.0983 40.0837 90.8253 43.3237 96.2523 43.3237C99.6273 43.3237 101.922 42.0817 103.569 40.4617L100.356 37.6537C99.4924 38.5987 98.1963 39.2737 96.5493 39.2737C94.7673 39.2737 93.5253 38.4907 93.4173 36.9247H103.515C103.542 36.5737 103.569 36.0877 103.569 35.6287ZM98.7363 33.9547H93.4173C93.5523 32.3887 94.6053 31.6327 96.0903 31.6327C97.5753 31.6327 98.6283 32.4157 98.7363 33.9547Z"
        fill="white"
      />
      <path
        d="M22.9015 65.9997V46.7217H17.9605V52.7427C16.8535 51.6897 15.4765 51.0957 13.8025 51.0957C9.72549 51.0957 7.1875 54.3357 7.1875 58.7097C7.1875 63.0027 9.72549 66.3237 13.8025 66.3237C15.4765 66.3237 16.8535 65.7027 17.9605 64.6497V65.9997H22.9015ZM17.9605 58.7367C17.9605 60.5997 17.0425 62.1117 15.1255 62.1117C13.2625 62.1117 12.3985 60.5727 12.3985 58.7367C12.3985 56.9007 13.2625 55.2807 15.1255 55.2807C17.0155 55.2807 17.9605 56.8737 17.9605 58.7367Z"
        fill="white"
      />
      <path
        d="M41.2606 58.6287C41.2606 54.0927 38.4796 51.0957 33.6736 51.0957C28.6786 51.0957 25.7896 54.3627 25.7896 58.7907C25.7896 63.0837 28.5166 66.3237 33.9436 66.3237C37.3186 66.3237 39.6136 65.0817 41.2606 63.4617L38.0476 60.6537C37.1836 61.5987 35.8876 62.2737 34.2406 62.2737C32.4586 62.2737 31.2166 61.4907 31.1086 59.9247H41.2066C41.2336 59.5737 41.2606 59.0877 41.2606 58.6287ZM36.4276 56.9547H31.1086C31.2436 55.3887 32.2966 54.6327 33.7816 54.6327C35.2666 54.6327 36.3196 55.4157 36.4276 56.9547Z"
        fill="white"
      />
      <path
        d="M58.7556 61.2477C58.7556 58.3047 56.3526 57.2247 52.5186 56.6037C50.8716 56.3337 49.4676 56.2527 49.4676 55.4427C49.4676 54.8217 50.2506 54.5247 51.3846 54.5247C53.0316 54.5247 54.5975 55.1727 55.5155 56.2797L58.7015 53.9847C57.3515 52.5537 54.8405 51.0957 51.3305 51.0957C47.7665 51.0957 44.8775 52.6077 44.8775 55.9017C44.8775 59.3307 48.0365 60.0867 50.9525 60.5727C52.7075 60.8697 54.0305 60.9777 54.0305 61.8147C54.0305 62.5437 53.0855 62.8677 51.9245 62.8677C50.3585 62.8677 48.4416 62.2737 47.4156 60.6537L43.9865 62.5707C45.5525 65.1897 48.4415 66.3237 51.7355 66.3237C56.0555 66.3237 58.7556 64.3797 58.7556 61.2477Z"
        fill="white"
      />
      <path
        d="M66.6365 49.8807V46.7217H61.6955V49.8807H66.6365ZM66.6365 65.9997V51.3927H61.6955V65.9997H66.6365Z"
        fill="white"
      />
      <path
        d="M85.3164 51.3927H80.3754V52.7697C79.2684 51.6897 77.8914 51.0957 76.2174 51.0957C72.1404 51.0957 69.6024 54.2277 69.6024 58.0617C69.6024 61.9227 72.1404 65.0277 76.2174 65.0277C77.8914 65.0277 79.2684 64.4337 80.3754 63.3807V64.1367C80.3754 66.2157 78.5934 66.9177 76.4064 66.9177C74.5704 66.9177 73.0044 66.5397 71.7084 65.7837L70.0884 69.5097C71.9244 70.5087 74.0034 70.9677 76.5414 70.9677C79.3224 70.9677 82.0224 70.3467 83.6154 68.5917C84.9924 67.0797 85.3164 65.2437 85.3164 62.8677V51.3927ZM80.3754 58.0347C80.3754 59.7357 79.3224 61.0587 77.5404 61.0587C75.7584 61.0587 74.8134 59.7627 74.8134 58.0347C74.8134 56.2797 75.7584 55.0647 77.5404 55.0647C79.3764 55.0647 80.3754 56.3337 80.3754 58.0347Z"
        fill="white"
      />
      <path
        d="M102.892 65.9997V56.1987C102.892 53.4987 101.434 51.0957 98.0594 51.0957C96.5744 51.0957 95.0624 51.6897 93.9554 52.7427V51.3927H89.0144V65.9997H93.9554V57.6027C93.9554 56.0907 94.9544 55.4157 96.0884 55.4157C97.6004 55.4157 97.9514 56.3337 97.9514 57.3327V65.9997H102.892Z"
        fill="white"
      />
      <circle cx="2.75" cy="63.75" r="2.75" fill="#35BCAA" />
    </svg>
  )
}

export default Logomark
