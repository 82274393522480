const colors = {
  white: '#fff',
  pageBg: '#fff',
  greyDark: '#242424',
  greyDark1: '#303030',
  greyDark2: '#4A4A4A',
  greyDark3: '#363636',
  greyDark4: '#555',
  greyDark5: '#999',
  base: '#242424', // Black
  secondary: '#e9e9e9', // Medium Gray
  tertiary: '#f3f3f3', // Light Gray
  highlight1: '#35BCAA',
  highlight2: '#FFD400',
  highlight3: '#F45197',
  greyLight1: '#DADADA',
  greyLight2: '#C9C9C9',
  greyLight3: '#F2F2F2',
  error: '#F7403A',
}

const font = {
  fontFamilyRegular: `'ars-maquette-web', 'sans-serif'`,
  fontFamilyItalic: `'ars-maquette-web', 'sans-serif'`,
  fontFamilyBold: `'ars-maquette-web', 'sans-serif'`,
  fontFamilyMono: `'Lucida Console', Monaco, monospace`,
  baseSizes: {
    sm: [`14px`, `17px`],
    md: [`17px`, `20px`],
    lg: [`19px`, `23px`],
  },
  titleSizes: {
    sm: [`32px`, `34px`],
    md: [`73px`, `74px`],
    lg: [`90px`, `90px`],
  },
  subTitleSizes: {
    sm: [`20px`, `110%`],
    md: [`27px`, `28px`],
    lg: [`34px`, `38px`],
  },
  buttonSizes: {
    sm: [`15px`, `100%`],
    md: [`16px`, `100%`],
    lg: [`17px`, `100%`],
  },
  buttonLrgSizes: {
    sm: [`16px`, `20px`],
    md: [`18px`, `19px`],
    lg: [`20px`, `23px`],
  },
  navLrgSizes: {
    sm: [`40px`, `41px`],
    md: [`37px`, `38px`],
    lg: [`45px`, `46px`],
  },
  navMiniSizes: {
    sm: [`12px`, `130%`],
    md: [`16px`, `130%`],
    lg: [`18px`, `130%`],
  },
  materialTitleSizes: {
    sm: [`32px`, `34px`],
    md: [`73px`, `74px`],
    lg: [`85px`, `85px`],
  },
  materialSizes: {
    sm: [`14px`, `22px`],
    md: [`16px`, `22px`],
    lg: [`18px`, `26px`],
  },
  materialSizesZoom: {
    sm: [`15px`, `18px`],
    md: [`22px`, `30px`],
    lg: [`24px`, `32px`],
  },
  brickSizes: {
    sm: [`14px`, `17px`],
    md: [`22px`, `25px`],
    lg: [`34px`, `36px`],
  },
  formTitleSizes: {
    sm: [`39px`, `39px`],
    md: [`53px`, `53px`],
    lg: [`60px`, `60px`],
  },
  inputFieldSizes: {
    sm: [`28px`, `28px`],
    md: [`36px`, `36px`],
    lg: [`50px`, `50px`],
  },
  inputFieldSizesSmall: {
    sm: [`14px`, `19px`],
    md: [`19px`, `22px`],
    lg: [`19px`, `22px`],
  },
  inputFieldSelectSizes: {
    sm: [`28px`, `38px`],
    md: [`36px`, `42px`],
    lg: [`50px`, `60px`],
  },
  inputFieldSelectSizesSmall: {
    sm: [`28px`, `38px`],
    md: [`19px`, `22px`],
    lg: [`19px`, `22px`],
  },
  inputErrorSizes: {
    sm: [`14px`, `17px`],
    md: [`17px`, `20px`],
    lg: [`19px`, `23px`],
  },
}

const space = [0, 4, 8, 16, 32, 64, 128, 256]

const sizes = {
  maxWidth: '1050px',
  maxWidthCentered: '800px',
  innerContentPadding: '50px',
  backWidth: '86px',
}

const breakpoints = ['900px', '1600px']

const responsive = {
  sm: '900px',
  md: '1600px',
  lg: '4000px',
}

const fontSizing = sizingObj => `
  font-size: ${sizingObj.lg[0]};
	line-height: ${sizingObj.lg[1]};
	@media screen and (max-width: 1600px) {
		font-size: ${sizingObj.md[0]};
    line-height: ${sizingObj.md[1]};
	}
	@media screen and (max-width: 900px) {
		font-size: ${sizingObj.sm[0]};
    line-height: ${sizingObj.sm[1]};
	}
`

const fontSizingZoom = (option, size) => {
  switch (option) {
    case 'title':
      return size == 'md' ? font.materialTitleSizes : font.materialTitleSizes
    case 'material':
      return size == 'md' ? font.materialSizes : font.materialSizesZoom
  }
}

const materialTextField = size => `
  width: 100%;
  .MuiFormLabel-root {
    font-family: ${font.fontFamilyRegular};
    font-weight: bold;
    ${fontSizing(
      size === 'sm' ? font.inputFieldSizesSmall : font.inputFieldSizes
    )};
    color: ${colors.greyDark5};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${colors.white};
  }
  .MuiInputLabel-formControl {
    transform: translate(0, ${size === 'sm' ? '24px' : '36px'}) scale(1)
  }
  .MuiInputLabel-shrink {
    transform: translate(0, -11px) scale(${size === 'sm' ? '0.9' : '0.4'});
    font-weight: normal;
  }
  .MuiInputBase-input {
    font-family: ${font.fontFamilyRegular};
    height: 1.5875em;
    ${fontSizing(
      size === 'sm' ? font.inputFieldSizesSmall : font.inputFieldSizes
    )};
    font-weight: bold;
    color: ${colors.white};
    
    :-webkit-autofill,
    :-webkit-autofill:hover, 
    :-webkit-autofill:focus {
      ${fontSizing(
        size === 'sm' ? font.inputFieldSizesSmall : font.inputFieldSizes
      )};
    }
    :-internal-autofill-selected {
      background: red;
    }
  }
  .MuiInput-underline:before {
    border-bottom: 3px solid ${colors.white};
  }
  .MuiFormLabel-root.Mui-disabled {
    color: ${colors.greyDark4};
  }
  .MuiInput-underline.Mui-disabled:before {
    border-bottom: 3px dotted ${colors.greyDark4};
  }
  .MuiInputBase-input.Mui-disabled {
    color: ${colors.greyDark4};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 3px solid ${colors.greyDark5};
  }
  .MuiInput-underline:after {
    border-color: ${colors.highlight1};
    border-width: 3px;
  }
  @media screen and (max-width: ${responsive.sm}) {
    .MuiFormControl-marginNormal {
      margin-top: 12px;
    }
  }
`

const materialFormControlLabel = isKnockout => `
  .MuiTypography-root.MuiTypography-body1 {
    font-family: ${font.fontFamilyRegular} !important;
    color: ${isKnockout ? colors.greyDark : colors.white1} !important;
    ${fontSizing(font.baseSizes)};
  }
`

const materialRadio = isKnockout => `
  font-family: ${font.fontFamilyRegular};
  color: ${isKnockout ? colors.greyDark : colors.greyDark1};
  &.MuiIconButton-colorSecondary {
    color: ${isKnockout ? colors.greyDark : colors.greyDark5};
  }
  &.MuiRadio-colorSecondary.Mui-checked {
    color: ${isKnockout ? colors.greyDark : colors.highlight2};
  }
  .MuiSvgIcon-root {
    font-size: 1.9rem;
  }
`

const materialCheckbox = isKnockout => `
  font-family: ${font.fontFamilyRegular};
  color: ${isKnockout ? colors.greyDark : colors.greyDark1};
  .MuiSvgIcon-root {
    fill: ${isKnockout ? colors.highlight1 : colors.greyDark1};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: rgba(53, 188, 170, .1) !important;
  }
  &.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: rgba(53, 188, 170, .1) !important;
  }
  .MuiTouchRipple-root {
    color: ${isKnockout ? colors.highlight1 : colors.greyDark1};
  }
`

const materialFormControlSelect = () => `
  width: 100%;
  .MuiInputBase-root {
    ${fontSizing(font.inputFieldSelectSizes)};
    font-family: ${font.fontFamilyRegular};
    font-weight: bold;
  }
  .MuiInput-underline:before {
    border-bottom: 3px solid ${colors.white};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 3px solid ${colors.greyDark5};
  }
  .MuiInput-underline:after {
    border-color: ${colors.highlight1};
    border-width: 3px;
  }
`

const materialSelect = () => `
  width: calc(100% - 4px);
  .MuiInputBase-input {
    color: ${colors.greyDark5};
    padding-bottom: 15px;
  }
  .MuiNativeSelect-icon {
    color: ${colors.greyDark5};
    top: calc(50% - 29px);
  }
  .MuiSvgIcon-root {
    font-size: 3.5rem;
  }
  @media screen and (max-width: ${responsive.md}) {
    .MuiNativeSelect-icon {
      top: calc(50% - 24px);
    }
    .MuiSvgIcon-root {
      font-size: 2.8rem;
    }
  }
`

const materialFormControlSelectSmall = () => `
  width: 100%;
  .MuiInputBase-root {
    ${fontSizing(font.inputFieldSelectSizesSmall)};
    font-family: ${font.fontFamilyRegular};
    font-weight: bold;
  }
  .MuiInput-underline:before {
    border-bottom: 3px solid ${colors.white};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 3px solid ${colors.greyDark5};
  }
  .MuiInput-underline:after {
    border-color: ${colors.highlight1};
    border-width: 3px;
  }
`

const materialSelectSmall = () => `
  width: calc(100% - 4px);
  .MuiInputBase-input {
    color: ${colors.white};
    padding-bottom: 15px;
  }
  .MuiNativeSelect-icon {
    color: ${colors.white};
    top: calc(50% - 22px);
  }
  .MuiSvgIcon-root {
    font-size: 2.3rem;
  }
`

const circleButton = borderColor => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  border: 2px solid ${borderColor};
  cursor: pointer;
  @media screen and (max-width: ${responsive.md}) {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
  @media screen and (max-width: ${responsive.md}) {
    width: 37px;
    height: 37px;
    border-radius: 37px;
  }
`

const pageCopy = () => `
  color: ${colors.white};
  h3 {
    margin-top: 0px;
    margin-bottom: 1em;
    color: ${colors.white};
    font-weight: bold;
  }
  h4 {
    margin-top: 1em;
    color: ${colors.white};
    margin-bottom: 0px;
  }
  p {
    color: ${colors.greyDark5};
    margin-top: 0px;
    margin-bottom: 1em;
    
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-bottom: 1em !important;
    }
  }
  ul {
    margin: 0;
    margin-bottom: 1em;
    padding-left: 19px;
  }
  li {
    margin-bottom: 1em;
    list-style: disc outside none;
    color: ${colors.greyDark5};
  }
  a {
    color: ${colors.white};
    border-bottom: 2px solid ${colors.highlight1};
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  hr {
    border: none;
    outline: none;
    border-bottom: 1px solid ${colors.greyDark2};
    margin: 2em 0px;
  }
  @media screen and (max-width: ${responsive.sm}) {
    h3 {
      margin-bottom: .7em;
    }
  }
`

const materialWidth = (fullScreen, fontSize) => {
  if (fontSize === 'md') {
    return `
      max-width: ${
        fullScreen ? `calc(40% - 22px)` : `calc(${(1 / 2) * 100}% - 0px)`
      };
      @media (max-width: 1600px) {
        max-width: ${
          fullScreen ? `calc(40% - 22px)` : `calc(${(6 / 8) * 100}% - 0px)`
        };
      }
      @media (max-width: 900px) {
        max-width: 100%;
      }
      @media print {
        max-width: 100%;
      }
    `
  } else if (fontSize === 'lg') {
    return `
      max-width: ${fullScreen ? `calc(60% - 22px)` : `calc(60% - 22px)`};
      @media (max-width: 1600px) {
        max-width: ${fullScreen ? `75%` : `75%`};
      }
      @media (max-width: 900px) {
        max-width: 100%;
      }
      @media print {
        max-width: 100%;
      }
    `
  }
}

const materialAssetWidth = (fullScreen, fontSize) => {
  if (fontSize === 'md') {
    return `
      max-width: ${fullScreen ? `calc(80% - 22px)` : `50%`};
      padding-right: ${fullScreen ? `0px` : `0%`};
      @media (max-width: 1600px) {
        max-width: ${
          fullScreen ? `calc(40% - 22px)` : `calc(${(6 / 8) * 100}% - 0px)`
        };
      }
      @media (max-width: 900px) {
        max-width: 100%;
      }
      @media print {
        max-width: 100%;
      }
    `
  } else if (fontSize === 'lg') {
    return `
      max-width: ${fullScreen ? `calc(80% - 22px)` : `calc(60% - 22px)`};
      @media (max-width: 900px) {
        max-width: calc(100% - 22px);
      }
      @media print {
        max-width: 100%;
      }
    `
  }
}

const materialThemeOptions = {
  dark: {
    bg: colors.greyDark,
    bgShade: colors.greyDark,
    fg: colors.white,
    sep: colors.greyDark3,
    tipBg: colors.greyDark1,
    tipBg2: '#444',
    gridNib: '#6d6d6d',
    highlight: colors.highlight1,
  },
  light: {
    bg: colors.white,
    bgShade: colors.greyLight3,
    fg: colors.greyDark1,
    sep: colors.greyLight1,
    tipBg: colors.greyLight3,
    tipBg2: '#DDD',
    gridNib: '#DDD',
    highlight: colors.highlight1,
  },
}

// Updates based on user preferences
const materialTheme = materialThemeOptions.light

const theme = {
  colors,
  font,
  space,
  fontSizing,
  fontSizingZoom,
  sizes,
  breakpoints,
  responsive,
  materialTextField,
  materialRadio,
  materialCheckbox,
  materialFormControlLabel,
  materialFormControlSelect,
  materialSelect,
  materialFormControlSelectSmall,
  materialSelectSmall,
  materialWidth,
  materialAssetWidth,
  materialThemeOptions,
  materialTheme,
  circleButton,
  pageCopy,
}

export default theme
