import React from 'react'
import styled from 'styled-components'

const Title = ({ children }) => {
  return <El>{children}</El>
}

export default Title

const El = styled.h1`
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  ${props => props.theme.fontSizing(props.theme.font.titleSizes)};
  margin-bottom: 0.4em;
  margin-top: 0px;
`
