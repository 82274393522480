import React from 'react'
import theme from '../../styles/theme'

export default () => {
  const fill = theme.colors.white
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5254 8.31152H4.05566L10.9736 1.39355L9.74902 0.179688L0.757812 9.1709L9.74902 18.1621L10.9736 16.9482L4.05566 10.0303H18.5254V8.31152Z" fill={fill} />
</svg>
  )
}
