import React from 'react'
import styled from 'styled-components'

const FormBox = ({ children, processing }) => {
  const opacity = processing === true ? `0.5` : `1`
  return (
    <El>
      <Inner opacity={opacity}>{children}</Inner>
    </El>
  )
}

export default FormBox

const El = styled.div`
  /* background: ${props => props.theme.colors.white};
  padding-top: 1em;
  padding-bottom: 1em; */
`

const Inner = styled.div`
  opacity: ${props => props.opacity};
`
