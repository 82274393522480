import React from 'react'
import { Logomark } from '../page/components'
import { Flex, Box } from 'reflexbox/styled-components'
import styled from 'styled-components'

export default ({ children }) => {
  return (
    <Header>
      <Flex flexWrap="nowrap">
        <Box
          width={[0, 1 / 10]}
          mr={[0, `${(1 / 10) * 100}%`]}
          justifyContent="flex-end"
          display="flex"
        >
          <Logomark />
        </Box>
        <Box width={[1, 7 / 12]}>{children}</Box>
      </Flex>
    </Header>
  )
}

const Header = styled.div`
  padding-top: 170px;
  margin-bottom: 60px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-top: 150px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-top: 100px;
    margin-bottom: 2em;
  }
`
