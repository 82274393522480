import React from 'react'
import theme from '../../styles/theme'

const IconClose = () => {
  const fill = theme.colors.white
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5049 2.20898L11.291 0.995117L6.67188 5.61426L2.05273 0.995117L0.838867 2.20898L5.45801 6.82812L0.838867 11.4473L2.05273 12.6611L6.67188 8.04199L11.291 12.6611L12.5049 11.4473L7.88574 6.82812L12.5049 2.20898Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconClose
